var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "test-table",
      staticStyle: { width: "50%", margin: "50px auto" },
    },
    [
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.date))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { property: "name", label: "Name", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              property: "address",
              label: "Address",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "20px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.handleDownload },
            },
            [_vm._v("Export")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                loading: _vm.downloadLoading,
                type: "primary",
                icon: "el-icon-document",
              },
              on: { click: _vm.doctien },
            },
            [_vm._v("Đọc")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }